<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap39AnObjectOrientedProgrammingLanguage"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 39: An object oriented programming language ?" image-name="object-oriented.jpg" image-alt="An object oriented programming language ?"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What are object-oriented languages?</p></li>
<li><p>What are common characteristics shared by object-oriented languages?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>abstraction</p></li>
<li><p>polymorphism</p></li>
<li><p>inheritance</p></li>
<li><p>composition</p></li>
<li><p>class</p></li>
<li><p>object</p></li>
<li><p>type struct</p></li>
<li><p>type embedding</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>If you are new to programming, you might not have been exposed to “object-oriented programming”. If you are a veteran programmer, there is a great chance that you have developed programs with an object-oriented language: you know what objects are, what encapsulation means ...etc.</p>
<p>The purpose of this section is to review all the characteristics of an object-oriented language. By doing so, we will attempt to understand why Go differs from those traditional languages.</p>
<p>Most of my students are afraid of the terms and notions linked to object-oriented programming. Do not be impressed by those complicated terms.</p>
<div id="what-is-an-object-oriented-language" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> What is an object-oriented language <a href="#what-is-an-object-oriented-language"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The concept is not new.</p>
<p>Back in 1966, the SIMULA language was object-oriented, then SMALLTALK-80 was first released in 1980. This last language was used a lot in schools to teach students the basics of object design.</p>
<p>What is the definition of this type of language? An object-oriented language is “a programming language that allows the user to express a program in term of objects and messages between those objects” (IEEE definition from <b-link class="citation" data-cites="institute1990ieee" href="#institute1990ieee" >[@institute1990ieee]</b-link>).</p>
<p>The definition highlight that it’s a category of programming languages that offers to the users <strong>objects</strong> and a way to pass <strong>messages</strong> between objects.</p>
<p>Object-oriented languages share some common characteristics :</p>
<ul>
<li><p>Classes and objects are program building blocks.</p></li>
<li><p>It offers a certain level of abstraction</p></li>
<li><p>It provides encapsulation mechanisms</p></li>
<li><p>Polymorphism is possible</p></li>
<li><p>Classes can inherit from one another</p></li>
</ul>
<p>Let’s iterate over each of those characteristics to determine if Go can be considered as an Object-Oriented Programming Language.</p>
<div id="class-object-instance" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Class, object &amp; instance <a href="#class-object-instance"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>This section will give some basic definitions of class, object, instance. Those are general definitions that apply to most object-oriented programming languages</p>
<div id="class" class="anchor"></div>
<h4 data-number="5.0.0.1"><span class="header-section-number">5.0.0.1</span> Class <a href="#class"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>This is a user-defined programming entity</p></li>
<li><p>It defines a set of attributes (each attribute has a type)</p></li>
<li><p>Attributes are also called properties, fields, data members</p></li>
<li><p>It defines methods (behaviors and operations) and their implementation.</p></li>
<li><p>Methods and attributes have a specific “visibility”. Attributes and methods can be called only in specific conditions.</p></li>
<li><p>Often, classes have a “constructor”, this is a method that is designed to create an object.</p>
<ul>
<li>Constructors are used to initialize the object “state”, ie. initialize values of attributes to specific values.</li>
</ul></li>
</ul>
<p>Here is an example of a class in C++ :</p>
<pre v-highlightjs><code class="go" v-pre >// C++
#include &lt;iostream&gt;
using namespace std;

class Teacher {
  public:
    string firstname; // Attribute
    string lastname;  // Attribute
    void sayHello() {  // Method
      cout &lt;&lt; &quot;Hello World!&quot;;
    }
};

int main() {
  Teacher myTeacher;
  myTeacher.sayHello();
  return 0;
}</code></pre>
<div id="objects" class="anchor"></div>
<h4 data-number="5.0.0.2"><span class="header-section-number">5.0.0.2</span> Objects <a href="#objects"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>Objects are created during program runtime. They are “run-time entit[ies]”<b-link class="citation" data-cites="gamma1995design" href="#gamma1995design" >[@gamma1995design]</b-link></p></li>
<li><p>A call to the class constructor creates an object.</p></li>
<li><p>An object is also called “an instance”</p></li>
<li><p>The process of creating an object is called “instanciation”.</p></li>
</ul>
<div id="go-classes" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Go classes ? <a href="#go-classes"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Go has “<strong>types</strong>” but no classes. A type specifies : “a set of values”</p>
<p>“operations and methods specific to those values” <strong>Struct types</strong> allow you to define a set of fields with a name and a type. Let’s take the example of a teacher object :</p>
<pre v-highlightjs><code class="go" v-pre >// object-oriented/classes/main.go 

type Teacher struct {
    id        int
    firstname string
    lastname  string
}</code></pre>
<p>We define the type Teacher that has three fields: id of type <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>, firstname and lastname of type <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span>.</p>
<p>We can also attach methods on this type :</p>
<pre v-highlightjs><code class="go" v-pre >func (t *Teacher) sayHiToClass(){
    fmt.Printf(&quot;Hi class my name is %s %s&quot;, t.firstname, t.lastname)
}</code></pre>
<p>We have defined a type struct with a data structure and attached methods. Let’s create an instance t of that object :</p>
<pre v-highlightjs><code class="go" v-pre >t := Teacher{12, &quot;John&quot;, &quot;Doe&quot;}</code></pre>
<p>Then we can use the methods defined on the object with that particular instance :</p>
<pre v-highlightjs><code class="go" v-pre >t.sayHiToClass()</code></pre>
<p>You can also create a type based on another type :</p>
<pre v-highlightjs><code class="go" v-pre >type StatusCode int</code></pre>
<p>And this type can also have methods :</p>
<pre v-highlightjs><code class="go" v-pre >func (s *StatusCode) String() string {
    return fmt.Sprintf(&quot;Status Code %d&quot;,s)
}</code></pre>
<p>In this case the set of values <span v-highlightjs><code class="go" v-pre style="display: inline">StatusCode</code></span> is limited to integers</p>
<ul>
<li><p>Go type structs are <strong>close</strong> to classes</p></li>
<li><p>Type structs have fields</p></li>
<li><p>You can define methods attached to types</p></li>
<li><p>Compared to other languages, there is no “constructors” out of the box.</p></li>
<li><p>Fields of a type struct are automatically initialized by Go (to the field type’s zero value).</p></li>
<li><p>In place of constructors, some libraries define a <span v-highlightjs><code class="go" v-pre style="display: inline">New</code></span> function :</p></li>
</ul>
<p>Here is a <span v-highlightjs><code class="go" v-pre style="display: inline">New</code></span> function from the module <span v-highlightjs><code class="go" v-pre style="display: inline">github.com/gin-gonic/gin</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >package gin

// New returns a new blank Engine instance without any middleware attached.
// ...
func New() *Engine {
    //...
}</code></pre>
<p>Here is a <span v-highlightjs><code class="go" v-pre style="display: inline">New</code></span> function defined into the standard package <span v-highlightjs><code class="go" v-pre style="display: inline">math/rand</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >package rand

// New returns a new Rand that uses random values from src
// to generate other random values.
func New(src Source) *Rand {
    //..
}</code></pre>
<p>And another one from the <span v-highlightjs><code class="go" v-pre style="display: inline">doc</code></span> package :</p>
<pre v-highlightjs><code class="go" v-pre >package doc

//...

func New(pkg *ast.Package, importPath string, mode Mode) *Package {
    //...
}</code></pre>
<ul>
<li><p>You can note that, generally, <span v-highlightjs><code class="go" v-pre style="display: inline">New</code></span> function returns a pointer</p></li>
<li><p>It’s also common to have a <span v-highlightjs><code class="go" v-pre style="display: inline">New</code></span> function that returns a pointer AND an <span v-highlightjs><code class="go" v-pre style="display: inline">error</code></span></p></li>
</ul>
<div id="abstraction" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Abstraction <a href="#abstraction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Another important concept of object-oriented programming is abstraction.</p>
<p>When we are building our code, we call methods or functions to perform a specific operation : <span v-highlightjs><code class="go" v-pre style="display: inline">u.store()</code></span></p>
<p>Here we are telling our program to call the method <span v-highlightjs><code class="go" v-pre style="display: inline">store</code></span>. It does not require you to know about how the method is implemented (how it is coded inside).</p>
<ul>
<li><p>The term abstraction comes from Latin <strong>abstractio.</strong>This term conveys the idea of separation, of taking away something.</p></li>
<li><p>Function and methods call are taking away the concrete implementation, the complex details.</p></li>
<li><p>The main advantage of hiding the implementation details is that you can change it without impacting the function call (if you do not change the function signature).</p></li>
</ul>
<div id="encapsulation" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Encapsulation <a href="#encapsulation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Encapsulation comes from the Latin term “capsula” which means “a small box”. In the French language, the term “capsule” refers to something that is boxed. In biology, this term is used to designate a cell’s membrane.</p>
<p>A capsule is a container in which you can box objects. Encapsulating something is equivalent to enclose it inside a capsule.</p>
<p>If we come back to computer science, encapsulation is a design technique used to group operations and data into isolated capsules of codes. The access to those operations and data is regulated, and those “capsules” define external interfaces to interact with them.</p>
<p>An object-oriented programming language gives the developer the ability to encapsulate objects. Is it the case with Go? Can we encapsulate things? Can we define strict external interfaces on packages?</p>
<p>The answer is yes! The concept of packages allows you to define a strict external interface.</p>
<div id="visibility" class="anchor"></div>
<h4 data-number="8.0.0.1"><span class="header-section-number">8.0.0.1</span> Visibility <a href="#visibility"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Let’s take an example. We define the <span v-highlightjs><code class="go" v-pre style="display: inline">packageA</code></span></p>
<pre v-highlightjs><code class="go" v-pre >// object-oriented/encapsulation/packageA/packageA.go
package packageA

import &quot;fmt&quot;

func DoSomething() {
    fmt.Println(&quot;do something&quot;)
}

func onePrivateFunction() {
    //...
}

func anotherPrivateFunction() {
    //...
}

func nothingToSeeHere() {
    //..
}</code></pre>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">packageA</code></span> defines only a public function <span v-highlightjs><code class="go" v-pre style="display: inline">DoSomething</code></span>. The outside world can use this function, but it will not compile if you try to access to a private function (<span v-highlightjs><code class="go" v-pre style="display: inline">onePrivateFunction</code></span>,<span v-highlightjs><code class="go" v-pre style="display: inline">anotherPrivateFunction</code></span> ...). There is no public or private keyword in the language; the visibility is defined by the case of the first letter of the defined elements (methods, types, constants ...)</p>
<div id="advantages-of-encapsulation" class="anchor"></div>
<h4 data-number="8.0.0.2"><span class="header-section-number">8.0.0.2</span> Advantages of encapsulation <a href="#advantages-of-encapsulation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>It hides the implementation to the user of the package.</p></li>
<li><p>It allows you to change the implementation without being worried about breaking something in your user’s code. You can only break something if you change one of your package’s exported (public) elements.</p></li>
<li><p>Maintenance and refactoring are easier to perform. Internal changes will seldom affect clients.</p></li>
</ul>
<div id="polymorphism" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Polymorphism <a href="#polymorphism"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Polymorphism is the capacity for something to have several forms. The words come from the term poly (which means several) and “morphism” which design forms, shapes of something.</p>
<p>How to apply that to a programming language? To understand polymorphism, you have to focus on operations, i.e., methods.</p>
<p>A system is said to be polymorphic if “the same operation can behave differently in different classes”<b-link class="citation" data-cites="rumbaugh1991object" href="#rumbaugh1991object" >[@rumbaugh1991object]</b-link>.</p>
<p>Let’s refine our teacher example. A school teacher is not the same as a university teacher; therefore we can define two different “classes”, two different struct types. The <span v-highlightjs><code class="go" v-pre style="display: inline">SchoolTeacher</code></span> type struct :</p>
<pre v-highlightjs><code class="go" v-pre >type SchoolTeacher struct {
    id        int
    firstname string
    lastname  string
}</code></pre>
<p>And the <span v-highlightjs><code class="go" v-pre style="display: inline">UniversityTeacher</code></span> type struct :</p>
<pre v-highlightjs><code class="go" v-pre >type UniversityTeacher struct {
    id         int
    firstname  string
    lastname   string
    department string
    specialty string
}</code></pre>
<p>The second type of teacher has more fields for its specialty and department.</p>
<p>Next, we will define the same operation for the two types of teachers :</p>
<pre v-highlightjs><code class="go" v-pre >func (t SchoolTeacher) SayHiToClass() {
    fmt.Printf(&quot;Hi class my name is %s %s\n&quot;, t.firstname, t.lastname)
}</code></pre>
<p>And for the other type, the implementation is slightly different :</p>
<pre v-highlightjs><code class="go" v-pre >func (t UniversityTeacher) SayHiToClass() {
    fmt.Printf(&quot;Hi dear students my name is %s %s I will teach you %s\n&quot;, t.firstname, t.lastname, t.specialty)
}</code></pre>
<p>The university teacher always specifies his field and department when he says hi to class. Whereas the school teacher doesn’t.</p>
<p>Here we have the same operation defined on two different types; we can define an interface Teacher :</p>
<pre v-highlightjs><code class="go" v-pre >type Teacher interface {
    SayHiToClass()
}</code></pre>
<p>Automatically the types <span v-highlightjs><code class="go" v-pre style="display: inline">SchoolTeacher</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">UniversityTeacher</code></span> will implement the interface (you do not have to specify it in the source code). Let’s create a slice composed of two objects :</p>
<pre v-highlightjs><code class="go" v-pre >john := UniversityTeacher{id:12,firstname:&quot;John&quot;,lastname:&quot;Doe&quot;,department:&quot;Science&quot;,specialty:&quot;Biology&quot;}
marc := SchoolTeacher{id:23,firstname:&quot;Marc&quot;,lastname:&quot;Chair&quot;}</code></pre>
<p>John is a university teacher in biology; Marc educates children. Their job is not the same, but they share a common operation: the class greeting operation. Therefore they implement the Teacher interface type. We can group those two objects in a slice composed of Teacher objects :</p>
<pre v-highlightjs><code class="go" v-pre >teachers := []Teacher{john,marc}</code></pre>
<p>We can then iterate over the slice and make them say hi to their class in their specific style :</p>
<pre v-highlightjs><code class="go" v-pre >// object-oriented/polymorphism/main.go 
func main() {
    //...
    teachers := []Teacher{john, marc}
    for _, t := range teachers {
        t.SayHiToClass()
    }
}</code></pre>
<p>In this last code listing, we have discovered the power of polymorphism :</p>
<ul>
<li><p>Go can find which method to call based on the type of t.</p></li>
<li><p>The same interface type designs different implementations <span v-highlightjs><code class="go" v-pre style="display: inline">Teacher</code></span></p></li>
</ul>
<div id="inheritance" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Inheritance <a href="#inheritance"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A child may inherit the genetic properties of its ancestors. But we cannot reduce children to their ancestors. When they grow up, they will build their specific properties.</p>
<p>The same inheritance concept applies to classes in object-oriented programming. When you create a complex application, often the objects identified <strong>share</strong> some common properties and operations.</p>
<p>For instance, every teacher has a name, a surname, a date of birth, an address, etc. School teachers and university teachers have both those properties. Why not create a super object that will hold those common properties and share them with the subclasses?</p>
<p>That’s the idea of inheritance. Objects will define their unique properties and operations and inherit its ancestor. This will reduce code repetition. Shared properties are only defined into the super object, just once.</p>
<p>Is it possible with Go? The answer is no. We will see an important design concept: type embedding. The main objective of this section is to see if we can see type embedding as <strong>a form of</strong>inheritance.</p>
<div id="single-type-embedding" class="anchor"></div>
<h2 data-number="10.1"><span class="header-section-number">10.1</span> Single type embedding <a href="#single-type-embedding"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can embed a type into another type. Let’s take our previous example. We have a type struct human with two fields :</p>
<pre v-highlightjs><code class="go" v-pre >type Human struct {
    firstname string
    lastname  string
}</code></pre>
<p>This represents a Human. We add him the capability to walk :</p>
<pre v-highlightjs><code class="go" v-pre >func (t Human) Walk() {
    fmt.Printf(&quot;I walk...\n&quot;)
}</code></pre>
<p>A school teacher and a university teacher are both humans; we can embed the type <span v-highlightjs><code class="go" v-pre style="display: inline">Human</code></span> into those two types :</p>
<pre v-highlightjs><code class="go" v-pre >type SchoolTeacher struct {
    Human
}
type UniversityTeacher struct {
    Human
    department string
    specialty string
}</code></pre>
<p>To do this, we just add the name of the type as a new field (without a name).</p>
<p>By doing so, we can define our two teachers :</p>
<pre v-highlightjs><code class="go" v-pre >john := UniversityTeacher{Human: Human{ firstname: &quot;John&quot;, lastname: &quot;Doe&quot;}, department: &quot;Science&quot;, specialty: &quot;Biology&quot;}
    marc := SchoolTeacher{Human: Human{firstname:&quot;Marc&quot;,lastname:&quot;Chair&quot;}}</code></pre>
<p>We can call the method Walk on john and marc :</p>
<pre v-highlightjs><code class="go" v-pre >// object-oriented/inheritance/single-type-embedding/main.go 
//...
func main() {
    john.Human.Walk()
    marc.Human.Walk()
    // or
    john.Walk()
    marc.Walk()
}</code></pre>
<p>Which will output :</p>
<pre v-highlightjs><code class="go" v-pre >I walk...
I walk...</code></pre>
<div id="multiple-type-embedding" class="anchor"></div>
<h2 data-number="10.2"><span class="header-section-number">10.2</span> Multiple type embedding <a href="#multiple-type-embedding"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You are not limited to <strong>one</strong> type embed into a type struct. You can embed several types. Let’s introduce the type <span v-highlightjs><code class="go" v-pre style="display: inline">Researcher</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >type Researcher struct {
    fieldOfResearch string
    numberOfPhdStudents int
}</code></pre>
<p>A <span v-highlightjs><code class="go" v-pre style="display: inline">Researcher</code></span> has a field of research and a certain number of Ph.D. students that he has to follow. University teachers are also Researchers. We can embed the type <span v-highlightjs><code class="go" v-pre style="display: inline">Researcher</code></span> into the type <span v-highlightjs><code class="go" v-pre style="display: inline">UniversityTeacher</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// object-oriented/inheritance/multiple-type-Embedding/main.go 

type UniversityTeacher struct {
    Human
    Researcher
    department string
    specialty string
}</code></pre>
<div id="name-conflicts" class="anchor"></div>
<h2 data-number="10.3"><span class="header-section-number">10.3</span> Name conflicts <a href="#name-conflicts"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>But there is an important limitation to type Embedding: name conflicts. If you want to embed two types that come from different packages but share the same name, your program will not compile. Let’s take an example. You have two packages <span v-highlightjs><code class="go" v-pre style="display: inline">packageA</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">packageB</code></span> each of them declares a type called <span v-highlightjs><code class="go" v-pre style="display: inline">MyType</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >package packageA

type MyType struct {
    id int
}

package packageB

type MyType struct {
    id int
}</code></pre>
<p>Now in your main package, you define a package <span v-highlightjs><code class="go" v-pre style="display: inline">MyOtherType</code></span> wich embed both types :</p>
<pre v-highlightjs><code class="go" v-pre >type MyOtherType struct {
    packageA.MyType
    packageB.MyType
}</code></pre>
<p>There is a duplication of a filed name. The two first fields are embed fields; therefore, their name is both <span v-highlightjs><code class="go" v-pre style="display: inline">MyType</code></span> in the type struct <span v-highlightjs><code class="go" v-pre style="display: inline">MyOtherType</code></span>. Having two fields with the same name is not possible. Your program will not compile :</p>
<pre v-highlightjs><code class="go" v-pre >typeEmbedding/nameClash/main.go:10:10: duplicate field MyType

Compilation finished with exit code 2</code></pre>
<div id="direct-access-to-properties-and-operations" class="anchor"></div>
<h2 data-number="10.4"><span class="header-section-number">10.4</span> Direct access to properties and operations <a href="#direct-access-to-properties-and-operations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In other object-oriented languages, a class <strong>B</strong> that inherits from <strong>A</strong> can access <strong>directly</strong> all the properties and operation defined in <strong>A</strong>.</p>
<p>What does it mean? Let’s take the example of PHP. We define a class <span v-highlightjs><code class="go" v-pre style="display: inline">A</code></span> that has the method <span v-highlightjs><code class="go" v-pre style="display: inline">sayHi</code></span> (public means that the outside world can access it, it’s like a method name starting with an uppercase character) :</p>
<pre v-highlightjs><code class="go" v-pre >// PHP Code
class A
{
    public function sayHi()
    {
        echo &#39;Hi !&#39;;
    }

}</code></pre>
<p>Then in the same script, we define the class <span v-highlightjs><code class="go" v-pre style="display: inline">B</code></span> that inherits from the class <span v-highlightjs><code class="go" v-pre style="display: inline">A</code></span>. The keyword in PHP is <span v-highlightjs><code class="go" v-pre style="display: inline">extends</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// PHP Code
class B extends A
{
    public function sayGoodbye()
    {
        echo &#39;Goodbye !&#39;;
    }
}</code></pre>
<p>The class <span v-highlightjs><code class="go" v-pre style="display: inline">B</code></span> is an heir of <span v-highlightjs><code class="go" v-pre style="display: inline">A</code></span>. Therefore we can access the methods of directly <span v-highlightjs><code class="go" v-pre style="display: inline">A</code></span> with an object of type <span v-highlightjs><code class="go" v-pre style="display: inline">B</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// PHP Code
// $b is a new instance of class B
$b = new B();
// we call the method sayHi, which is defined in class A
$b-&gt;sayHi();
// We call the method sayGoodbye defined in the class B
$b-&gt;sayGoodbye();</code></pre>
<p>Here we have direct access to properties and operations defined on A from B.</p>
<p>We can access an embedded type’s properties directly with type embedding. We can write :</p>
<pre v-highlightjs><code class="go" v-pre >john.Walk()</code></pre>
<p>We can also write :</p>
<pre v-highlightjs><code class="go" v-pre >john.Human.Walk()</code></pre>
<div id="composition-over-inheritance" class="anchor"></div>
<h2 data-number="10.5"><span class="header-section-number">10.5</span> Composition over inheritance <a href="#composition-over-inheritance"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Type embedding and inheritance have the same goal: avoid code repetition and maximize code reuse. But composition and inheritance are two very different designs. Go approach is composition over inheritance.</p>
<p>I will write the distinction made by the authors of the very famous <u>Design Patterns: Elements of Reusable Object-Oriented Software</u> (<b-link class="citation" data-cites="gamma1995design" href="#gamma1995design" >[@gamma1995design]</b-link>):</p>
<ul>
<li><p><strong>Inheritance is a “white-box reuse” strategy</strong> : The heir classes have access to internal properties of their ancestors; internal properties and operations can be visible to the heir class.</p></li>
<li><p><strong>Composition is a “black-box reuse” strategy</strong> : The classes are composed together, the type <span v-highlightjs><code class="go" v-pre style="display: inline">A</code></span> that embed a type <span v-highlightjs><code class="go" v-pre style="display: inline">B</code></span> do not have access to all the internal properties and operations. Public fields and methods are accessible; private ones aren’t.</p></li>
</ul>
<p>Let’s take an example. We have a <span v-highlightjs><code class="go" v-pre style="display: inline">cart</code></span> that defines the type struct <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span>:</p>
<pre v-highlightjs><code class="go" v-pre >package cart

type Cart struct {
    ID     string
    locked bool
}</code></pre>
<p>This type struct has two fields named <span v-highlightjs><code class="go" v-pre style="display: inline">ID</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">locked</code></span>. The field <span v-highlightjs><code class="go" v-pre style="display: inline">ID</code></span> is exported. The field named <span v-highlightjs><code class="go" v-pre style="display: inline">locked</code></span> is not accessible from another package.</p>
<p>In our main program, we create another type struct named <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >package main

type User struct {
    cart.Cart
}</code></pre>
<p>The type <span v-highlightjs><code class="go" v-pre style="display: inline">cart.Cart</code></span> is embedded into <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span>. If we create a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span> we can only access the exported fields from <span v-highlightjs><code class="go" v-pre style="display: inline">cart.Cart</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >func main() {
    u := User{}
    fmt.Println(u.Cart.ID)
}</code></pre>
<p>If we call</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Println(u.Cart.locked)</code></pre>
<p>our program will not compile with the following error message :</p>
<pre v-highlightjs><code class="go" v-pre >typeEmbedding/blackBoxReuse/main.go:17:27: u.Cart.locked undefined (cannot refer to unexported field or method private)</code></pre>
<div id="advantages-of-composition-over-inheritance" class="anchor"></div>
<h2 data-number="10.6"><span class="header-section-number">10.6</span> Advantages of composition over inheritance <a href="#advantages-of-composition-over-inheritance"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>In the inheritance model, the heir class can access the ancestor class’s internals. The ancestor internals can be visible to the heir; the encapsulation rule is somewhat broken. Whereas with composition, you cannot access the ancestor class’s hidden parts. This technique ensures that your structs’ users will only use the exported methods and fields.</p></li>
<li><p>Composition is <u>generally</u> simpler than inheritance. If you are an experienced developer, you might have been confronted with an object-oriented design where inheritance is overly used. In such cases, the project is harder to understand, and newcomers have to spend hours with senior developers to understand the graph of classes.</p></li>
</ul>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="11.1"><span class="header-section-number">11.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>Generally, what is (are) the return type(s) of <span v-highlightjs><code class="go" v-pre style="display: inline">New</code></span> functions ?</p></li>
<li><p>What is encapsulation? Give an example in Go.</p></li>
<li><p>What is polymorphism? Explain it using your own words.</p></li>
<li><p>True or False. A Go type can inherit from another type.</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="11.2"><span class="header-section-number">11.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>Generally, what is (are) the return type(s) of <span v-highlightjs><code class="go" v-pre style="display: inline">New</code></span> functions ?</p>
<ol type="1">
<li><p>A pointer</p></li>
<li><p>Optionally an error</p></li>
</ol></li>
<li><p>What is encapsulation? Give an example.</p>
<ol type="1">
<li><p>Encapsulation is a design technique used to group operations and data into isolated “capsules” of codes.</p></li>
<li><p>Isolated = access to data and methods is restricted.</p></li>
<li><p>A variable, constant, type, function, or method defined in a Go package is accessible in another package only if it’s exported.</p></li>
<li><p>Ex: see question 4 snippet below.</p></li>
<li><p>The type <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span> is exported. The function <span v-highlightjs><code class="go" v-pre style="display: inline">total</code></span> is not exported. The function <span v-highlightjs><code class="go" v-pre style="display: inline">New</code></span> is exported</p></li>
<li><p>From another package, only <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">New</code></span> are accessible</p></li>
</ol></li>
<li><p>What is polymorphism? Explain it using your own words.</p>
<ol type="1">
<li><p>The word polymorphic designates an entity that can have different forms.</p></li>
<li><p>Polymorphism is achieved when a single symbol can represent different types.</p></li>
<li><p>An interface type can represent different types (implementations).</p></li>
</ol></li>
<li><p>True or False. A Go type can inherit from another type.</p>
<ol type="1">
<li><p>False.</p></li>
<li><p>There is no inheritance mechanism in Go.</p></li>
<li><p>However, Go allows the composition of types</p></li>
<li><p>You can embed a type in another type.</p></li>
</ol></li>
</ol>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// question 4 snippet
package cart

//...

type Cart struct {
    ID string
    Items
}

func total() {
    //...
}

func New() *Cart {
    //...
}</code></pre>
<div id="key-takeaways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>An object-oriented language is “a programming language that allows the user to express a program in term of objects and messages between those objects”<b-link class="citation" data-cites="institute1990ieee" href="#institute1990ieee" >[@institute1990ieee]</b-link>. Objects are instances of classes.</p>
<ul>
<li><p>A class is a user-defined programming entity with behaviors and methods.</p></li>
<li><p>In some languages, you will need to define constructors. Which are specific methods intended to initialize an object.</p></li>
<li><p>An object encapsulates <strong>data</strong> and <strong>behaviors</strong> (methods)</p></li>
</ul></li>
<li><p>An object-oriented language has the following characteristics :</p>
<ul>
<li><p>Classes and objects are program building blocks.</p></li>
<li><p>It offers a certain level of abstraction</p></li>
<li><p>It provides encapsulation mechanisms</p></li>
<li><p>Polymorphism is possible</p></li>
<li><p>Classes can inherit from one another</p></li>
</ul></li>
<li><p>Go has types but no classes.</p>
<ul>
<li><p>A type specify a “set of values” along with “methods specific to those values”</p></li>
<li><p>Some libraries define <span v-highlightjs><code class="go" v-pre style="display: inline">New</code></span> functions that we can assimilate as “constructors”.</p></li>
</ul></li>
<li><p>Go offers “<strong>abstraction</strong>” to developers. For instance: you do not need to know the actual implementation of a method to call it.</p></li>
<li><p>“<strong>Encapsulation</strong>” is a characteristic of Object-Oriented languages</p></li>
<li><p>Go types, variables, and constants can be exported, making them available from another package.</p>
<ul>
<li>Thanks to this mechanism, the code can be “encapsulated”. Access to implementation details can be forbidden to the caller.</li>
</ul></li>
<li><p><strong>Polymorphism</strong> is achieved when a single symbol can represent different types.</p>
<ul>
<li>An interface type can represent different types (implementations).</li>
</ul></li>
<li><p>There is no <strong>inheritance</strong> mechanism in Go. However, types can be <strong>composed</strong> together via type embedding</p>
<ul>
<li><p><strong>Inheritance is a “white-box reuse” strategy</strong> : The heir classes have access to <strong>all</strong> internal properties of their ancestors, all internal properties and operations are visible to the heir class.</p></li>
<li><p><strong>Composition is a “black-box reuse” strategy</strong> : The classes are composed together, the type <span v-highlightjs><code class="go" v-pre style="display: inline">A</code></span> that embed a type <span v-highlightjs><code class="go" v-pre style="display: inline">B</code></span> do not have access to all the internal properties and operations. Public fields and methods are accessible; private ones aren’t.</p></li>
</ul></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >package main

type User struct {
    // Cart is embedded into type User
    // this is called composition
    cart.Cart
}
func main() {
    u := User{}
    fmt.Println(u.Cart.ID)
}</code></pre>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap39AnObjectOrientedProgrammingLanguage"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap38Generics'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Generics</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap40UpgradingOrDowngradingGo'}">
									<p><u><small>Next</small></u></p>
									<p><small>Upgrading or Downgrading Go</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "An object oriented programming language ? - Practical Go Lessons"
const description = "Is Go an object oriented programming language? Encapsulation, Polymorphism, Abstraction, Inheritance, Composition concepts"

export default {
  name: "Chap39AnObjectOrientedProgrammingLanguage",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
